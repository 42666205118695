*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  height: 100%;
  font-size: 62.5%;
}
@media only screen and (min-width: 56.25em) {
  html {
    font-size: 68.75%;
  }
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 87.5%;
  }
}
body {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}
body {
  font-family: Georgia, "Times New Roman", Times, serif;
  background-color: #f6f6f6;
}
.wf-active body {
  font-family: "Montserrat", sans-serif;
}
input,
textarea,
button {
  font-family: inherit;
}
.heading-primary {
  font-size: 5.7rem;
  font-weight: 700;
}
.heading-tertiary {
  font-size: 1.4rem;
  font-weight: 600;
}
.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 3rem !important;
}
.u-margin-bottom-large {
  margin-bottom: 4.5rem !important;
}
.u-margin-bottom-xlarge {
  margin-bottom: 6rem !important;
}
.u-margin-top-small {
  margin-top: 1.5rem !important;
}
@-webkit-keyframes slidein {
  0% {
    transform: translateY(-22rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@keyframes slidein {
  0% {
    transform: translateY(-22rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes swal-custom-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swal-custom-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes swal-custom-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.5);
    opacity: 0.4;
  }
  80% {
    transform: scale(0.3);
    opacity: 0.2;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes swal-custom-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.5);
    opacity: 0.4;
  }
  80% {
    transform: scale(0.3);
    opacity: 0.2;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}
@media only screen and (min-width: 75em) {
  .login {
    width: 50%;
  }
}
.login__logo-box {
  position: relative;
}
.login__logo {
  width: 148px;
}
@media only screen and (min-width: 75em) {
  .login__logo {
    width: 158px;
  }
}
.login__form-box {
  text-align: initial;
}
.login__label {
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: 2rem;
  color: #6a6a6a;
}
.login__input-box {
  position: relative;
}
.login__input {
  display: block;
  margin-bottom: 2rem;
  width: 100%;
  background-color: white;
  border: none;
  -webkit-filter: none;
  filter: none;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  padding: 1.4rem 5rem;
  border-radius: 100px;
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.09);
  color: #343434;
  font-size: 1.5rem;
  transition: 0.2s;
}
.login__input:focus {
  outline: none;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.16);
}
.login__input:focus + .login__input--icon {
  color: #078ed6;
}
.login__input--icon {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  color: #0073b1;
  font-size: 1.3rem;
  transition: all 0.2s;
}
.login__pass-fg-box::after {
  content: "";
  clear: both;
  display: table;
}
.login__pass-fg-link {
  float: right;
  transition: all 0.2s;
}
.login__pass-fg-link:visited,
.login__pass-fg-link:link {
  color: #343434;
  text-decoration: none;
}
.login__pass-fg-link:hover {
  color: rgba(52, 52, 52, 0.9);
}
.login__btn-box {
  text-align: center;
  margin-top: 6rem;
}
.login__error {
  padding: 0 1.3rem;
  color: #e8646e;
  margin-bottom: 2rem;
  margin-top: -0.5rem;
  border-radius: 100px;
}
.btn {
  background-color: #0084cb;
  color: white;
  border: none;
  padding: 1rem 2.4rem;
  width: 100%;
  border-radius: 100px;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s;
}
.btn:hover {
  background-color: #078ed6;
}
.btn--logout {
  cursor: pointer;
  background-color: #283e4a;
  border: none;
  font-size: 1.4rem;
  color: white;
}
.btn--logout:hover {
  color: #f6f6f6;
}
.btn--form {
  margin-top: 4rem;
  background-color: #76d460;
  transition: all 0.2s;
}
.btn--form:hover {
  background-color: #7adb65;
}
.btn-dialog {
  border: 0;
  border-radius: 0.25em;
  background-color: #2778c4;
  color: #fff;
  font-size: 1.3em;
  display: block;
  padding: 2rem;
  margin: 2rem auto 0.75em auto;
  padding: 0.625em 1.1em;
  box-shadow: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s;
}
.btn-dialog:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.btn-notifications {
  padding: 0.2rem;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
  font-size: 1.7rem;
  font-weight: 600;
  background-color: #bcccd1;
  color: #283e4a;
  border: none;
  width: 100%;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
}
.btn-notifications:hover {
  background-color: #9fc4cf;
}
.wave {
  z-index: -1000;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 1000px;
}
@media only screen and (min-width: 37.5em) {
  .wave {
    width: 1200px;
    bottom: -7%;
  }
}
@media only screen and (min-width: 75em) {
  .wave {
    width: 1800px;
    bottom: -29%;
  }
}
@media only screen and (min-width: 112.5em) {
  .wave {
    width: 4000px;
    bottom: -40%;
    left: 0;
  }
}
.logo-route {
  position: fixed;
  right: -4%;
  top: 5%;
  width: 30%;
  z-index: -1000;
  opacity: 0.05;
}
.pickup {
  display: flex;
  justify-content: space-around;
  background-color: white;
  border: 1px solid #ebebeb;
  box-shadow: 0 0.2rem 0.3rem #ebebeb;
  transition: all 0.2s;
}
.pickup:not(:first-of-type) {
  margin-top: 2rem;
}
.pickup__label-list {
  list-style-type: none;
  position: absolute;
  top: 0.8rem;
  left: 0.5rem;
  width: 12%;
}
.pickup__label-list-item {
  margin-bottom: 1rem;
}
.pickup--danger {
  background-color: #ff3c4b;
  color: white;
}
.pickup--danger .pickup__link:link,
.pickup--danger .pickup__link:visited,
.pickup--danger .pickup__link[type="button"] {
  color: white;
}
.pickup--danger .pickup__link--note-btn {
  color: white;
}
.pickup--danger .pickup__street {
  color: white;
}
.pickup--warning {
  background-color: #ffb424;
  color: white;
}
.pickup--warning .pickup__link:link,
.pickup--warning .pickup__link:visited,
.pickup--warning .pickup__link[type="button"] {
  color: white;
}
.pickup--warning .pickup__link--note-btn {
  color: white;
}
.pickup--warning .pickup__street {
  color: white;
}
.pickup .pickup__left-column {
  padding: 1rem;
}
@media only screen and (min-width: 37.5em) {
  .pickup .pickup__left-column {
    padding: 1.2rem;
  }
}
.pickup .pickup__right-column {
  padding: 0.7rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup .pickup__right-column {
    padding: 1.2rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup .pickup__right-column {
    padding: 1rem;
  }
}
.pickup .pickup__first-row {
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup .pickup__first-row {
    margin-bottom: 3rem;
  }
}
.pickup .pickup__name {
  font-size: 1.3rem;
}
.pickup .pickup__street {
  font-size: 1.2rem;
}
.pickup .pickup__ETA {
  height: 4.5rem;
  width: 4.5rem;
}
.pickup .pickup__ETA--number {
  font-size: 1.3rem;
}
.pickup .pickup__ETA--mins {
  font-size: 0.7rem;
}
.pickup .pickup__btn {
  padding: 0.7rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup .pickup__btn {
    padding: 1rem;
  }
}
.pickup--secondary {
  width: 85%;
  margin: 0 auto;
  margin-top: 2.5rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup--secondary {
    width: 80%;
  }
}
@media only screen and (min-width: 37.5em) {
  .pickup--secondary {
    width: 75%;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup--secondary {
    margin: initial;
    margin-left: auto;
    width: 82%;
  }
}
.pickup--secondary .pickup__left-column {
  padding: 1.2rem;
}
.pickup--alternative {
  width: 100%;
  position: relative;
}
.pickup--garage,
.pickup--extra {
  position: relative;
}
.pickup__list {
  transition: all 0.2s;
}
@media only screen and (min-width: 56.25em) {
  .pickup__list--pending {
    padding-top: 2rem;
    width: 42%;
    overflow-y: auto;
    background-color: #ebebeb;
    scrollbar-width: thin;
    scrollbar-color: #6a6a6a #ebebeb;
  }
  .pickup__list--pending::-webkit-scrollbar {
    width: 7px;
  }
  .pickup__list--pending::-webkit-scrollbar-track {
    background: #ebebeb;
  }
  .pickup__list--pending::-webkit-scrollbar-thumb {
    background-color: #6a6a6a;
    border-radius: 20px;
    border: 3px solid #6a6a6a;
  }
}
.pickup__list--closest {
  width: 90%;
  background-color: #f6f6f6;
  margin: 0 auto;
}
.pickup__list--closest .pickup {
  padding: 1rem;
}
.pickup__list--closest .pickup__address-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}
.pickup__list--closest .pickup__second-row {
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  padding-right: 1.5rem;
}
.pickup__list--closest .pickup__right-column {
  display: initial;
  padding: initial;
}
.pickup__list--closest .pickup__right-column--garage {
  display: flex;
  flex-direction: initial;
  flex: 0.4;
  justify-content: initial;
}
.pickup__list--closest .pickup__name {
  margin-bottom: 0.4rem;
}
.pickup__list--closest .pickup__timewindow {
  margin-bottom: 0.4rem;
}
.pickup__list--closest .pickup__btn {
  display: inline-block;
  position: relative;
  padding: 2.8rem;
  width: 45%;
  top: 50%;
  transform: translateY(-50%);
}
.pickup__list--closest .pickup__btn:not(:last-of-type) {
  margin-bottom: initial;
  margin-right: 0.7rem;
}
.pickup__list--closest .pickup__btn--garage {
  width: 100%;
}
.pickup__list--closest .pickup__btn--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pickup__list--closest .pickup__link:link:first-of-type,
.pickup__list--closest .pickup__link:visited:first-of-type,
.pickup__list--closest .pickup__link[type="button"]:first-of-type {
  padding-right: initial;
}
.pickup__list--closest .pickup__link--divider {
  height: 50%;
  border-right: 1px solid #ebebeb;
}
.pickup__list--closest .pickup__link--note-btn {
  border-left: initial;
  padding: initial;
}
.pickup__list--closest .pickup__link--details-btn {
  border-left: initial;
  padding: initial;
}
.pickup__list--closest .pickup__link--phone-btn {
  border-left: initial;
  padding: initial;
}
.pickup__list--closest .pickup__link--icon {
  margin-left: initial;
}
.pickup__list--closest .pickup__message-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  top: 20%;
  text-align: center;
}
.pickup__list--completed .pickup {
  position: relative;
  padding: 2.5rem 1rem;
}
.pickup__list--completed .pickup__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.8;
}
.pickup__list--completed .pickup__left-column {
  padding: initial;
}
.pickup__list--completed .pickup__first-row {
  margin-bottom: initial;
}
.pickup__list--completed .pickup__right-column {
  justify-content: center;
  padding: 0 1rem;
}
.pickup__list--completed .pickup__address-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
}
.pickup__list--completed .pickup__btn {
  padding: 0 1rem;
  background-color: white;
}
.pickup__list--completed .pickup__time-container {
  display: flex;
  align-items: center;
}
.pickup__list--completed .pickup__time {
  color: #6a6a6a;
  font-size: 1.5rem;
  margin-left: 1rem;
}
.pickup__list--completed .pickup__icon--recover {
  position: relative;
  font-size: 3.5rem;
  color: #76d460;
  z-index: 1000;
}
.pickup__list--completed .pickup__icon--clock {
  font-size: 3rem;
  color: #6a6a6a;
}
.pickup__list--completed .pickup__alt-label {
  left: initial;
  right: 0.5rem;
  color: white;
}
.pickup__list--completed .pickup__alt-label--discarded {
  background-color: #e8646e;
}
.pickup__list--completed .pickup__alt-label--completed {
  background-color: #76d460;
  margin-bottom: 0.5rem;
}
.pickup__list-container {
  scrollbar-width: thin;
  scrollbar-color: #6a6a6a #ebebeb;
}
.pickup__list-container::-webkit-scrollbar {
  width: 7px;
}
.pickup__list-container::-webkit-scrollbar-track {
  background: #ebebeb;
}
.pickup__list-container::-webkit-scrollbar-thumb {
  background-color: #6a6a6a;
  border-radius: 20px;
  border: 3px solid #6a6a6a;
}
@media only screen and (min-width: 56.25em) {
  .pickup__list-container--closest {
    width: 58%;
    overflow-y: auto;
    padding-top: 2rem;
    position: relative;
  }
}
.pickup__list-container--completed {
  width: 100%;
  overflow-y: auto;
  padding-top: 2rem;
}
.pickup__alt-label {
  background-color: #f9f871;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.3rem 0.5rem;
  color: black;
  position: relative;
}
.pickup__alt-label--garage {
  height: 15px;
  display: block;
  background-color: #fff;
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px;
  background-image: linear-gradient(
      45deg,
      black 25%,
      transparent 25%,
      transparent 75%,
      black 75%,
      black
    ),
    linear-gradient(
      45deg,
      black 25%,
      transparent 25%,
      transparent 75%,
      black 75%,
      black
    );
}
.pickup__alt-label--extra {
  background-color: #0073b1;
  color: white;
}
.pickup__left-column {
  flex: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 25.6875em) {
  .pickup__left-column {
    padding: 2rem;
  }
}
@media only screen and (min-width: 37.5em) {
  .pickup__left-column {
    padding: 2rem 0rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup__left-column {
    padding: 1.5rem 0rem;
  }
}
.pickup__right-column {
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
}
.pickup__right-column--garage {
  justify-content: center;
}
.pickup__first-row {
  display: flex;
  align-items: center;
  margin-bottom: 3.5rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup__first-row {
    margin-bottom: 4rem;
  }
}
.pickup__left-column-container {
  width: 100%;
}
@media only screen and (min-width: 37.5em) {
  .pickup__left-column-container {
    width: 90%;
  }
}
.pickup__ETA-container {
  display: flex;
  flex: none;
}
.pickup__address-container {
  margin-left: 3rem;
  flex: auto;
  z-index: 10;
}
.pickup__second-row {
  display: flex;
  flex-wrap: wrap;
}
.pickup__ETA {
  height: 5.5rem;
  width: 5.5rem;
  background-color: #283e4a;
  border-radius: 50%;
  color: white;
  position: relative;
}
.pickup__ETA--negative {
  background-color: #e61f2f;
}
.pickup__ETA--extra {
  background-color: transparent;
}
.pickup__ETA--values {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pickup__ETA--number {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
}
.pickup__ETA--mins {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
}
.pickup__name {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.pickup__street {
  font-size: 1.5rem;
  font-weight: 400;
  color: #6a6a6a;
}
.pickup__timewindow {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.pickup__icon {
  font-size: 1.7rem;
  vertical-align: middle;
  margin-right: 1rem;
  color: #0073b1;
}
.pickup__link:link,
.pickup__link:visited,
.pickup__link[type="button"] {
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pickup__link:link:first-of-type,
.pickup__link:visited:first-of-type,
.pickup__link[type="button"]:first-of-type {
  padding-right: 0.7rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup__link:link:first-of-type,
  .pickup__link:visited:first-of-type,
  .pickup__link[type="button"]:first-of-type {
    padding-right: 2rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup__link:link:first-of-type,
  .pickup__link:visited:first-of-type,
  .pickup__link[type="button"]:first-of-type {
    padding-right: 1.4rem;
  }
}
.pickup__link[type="button"] {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.pickup__link--note-btn,
.pickup__link--details-btn,
.pickup__link--phone-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  border-left: 1px solid #ebebeb;
  padding: 0 0.7rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup__link--note-btn,
  .pickup__link--details-btn,
  .pickup__link--phone-btn {
    padding: 0 2rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup__link--note-btn,
  .pickup__link--details-btn,
  .pickup__link--phone-btn {
    padding: 0 1.4rem;
  }
}
.pickup__link--icon {
  color: #0073b1;
  font-size: 2.6rem;
  vertical-align: middle;
  margin-bottom: 0.3rem;
}
.pickup__link--icon:hover {
  opacity: 0.9;
}
.pickup__link--divider {
  height: 100%;
  border-right: 1px solid #ebebeb;
}
.pickup__btn {
  display: block;
  flex-basis: 50%;
  border: none;
  border-radius: 12px;
  padding: 1rem 1.2rem;
  cursor: pointer;
}
@media only screen and (min-width: 25.6875em) {
  .pickup__btn {
    padding: 1rem 1.2rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup__btn {
    padding: 1rem 1.2rem;
  }
}
.pickup__btn:not(:last-of-type) {
  margin-bottom: 0.3rem;
}
.pickup__btn--complete {
  background-color: #76d460;
}
.pickup__btn--complete-garage {
  flex-basis: 70%;
}
.pickup__btn--discard {
  background-color: #e8646e;
}
.pickup__btn--postpone {
  background-color: #0073b1;
  padding: 1rem;
}
.pickup__btn--icon {
  color: white;
  font-size: 3.5rem;
}
.pickup__btn--icon-postpone {
  font-size: 2.5rem;
}
.pickup__message-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  top: 20%;
  text-align: center;
}
.pickup__message {
  font-size: 1.8rem;
}
.dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 46em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
  margin: auto;
  -webkit-animation: swal-custom-show 0.3s;
  animation: swal-custom-show 0.3s;
}
.dialog-wide {
  width: 66% !important;
}
.dialog--hide {
  -webkit-animation: swal-custom-hide 0.1s ease-in-out;
  animation: swal-custom-hide 0.1s ease-in-out;
}
.dialog__container {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-y: auto;
}
.dialog__header {
  position: relative;
  max-width: 100%;
  margin: 0.5em 0 1em 0;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.dialog__option-container {
  display: flex;
  justify-content: center;
}
.dialog__option {
  background-color: #f6f6f6;
  width: 25%;
  max-width: 130px;
  text-align: center;
  padding: 2rem 0.5rem;
  cursor: pointer;
  border: none;
  border-radius: 12px;
}
.dialog__option:not(:last-of-type) {
  margin-right: 0.8rem;
}
@media only screen and (min-width: 30em) {
  .dialog__option {
    padding: 2.5rem 0.5rem;
  }
}
.dialog__disclaimer {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #595959;
}
.dialog__icon {
  color: #595959;
  font-size: 2.2rem;
}
.dialog__message-box {
  margin-top: 2rem;
}
.dialog__textarea {
  display: block;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  border: none;
  font-size: 1.3rem;
}
.dialog__textarea::-webkit-input-placeholder {
  font-size: 1.3rem;
  color: #6a6a6a;
}
.dialog__textarea:-ms-input-placeholder {
  font-size: 1.3rem;
  color: #6a6a6a;
}
.dialog__textarea::-ms-input-placeholder {
  font-size: 1.3rem;
  color: #6a6a6a;
}
.dialog__textarea::placeholder {
  font-size: 1.3rem;
  color: #6a6a6a;
}
.dialog__photo-adder-container {
  margin-bottom: 1.8rem;
  overflow: auto;
  text-align: center;
}
.dialog__photo-adder-label {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  display: inline-block;
}
.dialog__progress-shower-container {
  padding: 1.5rem 0;
}
.dialog__progress-shower-text {
  text-align: center;
  font-size: 1.7rem;
}
.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  font-weight: 400;
}
.loader__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loader__icon {
  color: #283e4a;
  font-size: 5rem;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.loader__message {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 600;
}
.loader--local {
  position: initial;
}
.loader--local .loader__container {
  position: initial;
  transform: initial;
}
.loader--local .loader__icon {
  font-size: 3rem;
}
.loader--actions {
  background-color: rgba(235, 235, 235, 0.7);
  z-index: 10000000000000000000000000000000000000000000000000;
}
.settings__list > li:first-child {
  border-top: 1px solid #ebebeb;
}
.settings__list-item {
  position: relative;
  background-color: white;
  padding: 2.2rem;
  font-size: 1.1rem;
  border-bottom: 1px solid #ebebeb;
}
.settings__list-item::after {
  content: "";
  clear: both;
  display: table;
}
.settings__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  color: #0073b1;
}
.settings__icon--arrow {
  position: initial;
  top: initial;
  transform: initial;
  float: right;
  color: black;
  margin-right: 1rem;
}
.settings__paragraph {
  display: inline-block;
  margin-left: 4.5rem;
  vertical-align: middle;
}
.settings__paragraph--sub {
  display: block;
  font-size: 1rem;
  color: #6a6a6a;
}
.settings__link:link,
.settings__link:visited {
  text-decoration: none;
  color: black;
}
.google-maps__container {
  width: 100%;
  height: calc(100% - 4.7rem - 4.2rem - 4.9rem);
  position: absolute;
  left: 0;
}
.google-maps__container--landscape-mode {
  height: calc(100% - 4.9rem - 2.8rem);
}
.google-maps__link {
  text-decoration: none;
}
.google-maps__modal {
  position: relative;
  z-index: 10000;
  color: black;
  padding: 1.4rem;
  box-shadow: 0 0.2rem 0.7rem rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  font-weight: 600;
  color: #343434;
}
.google-maps__modal--landscape-mode {
  box-shadow: initial;
}
.google-maps__modal--close {
  box-shadow: initial;
  background-color: rgba(235, 235, 235, 0.8);
  padding: 0.2rem 1.4rem;
}
.google-maps__message--close::after {
  content: "";
  clear: both;
  display: table;
}
.google-maps__icon {
  font-size: 2.1rem;
  margin-right: 2rem;
  vertical-align: middle;
  color: #343434;
}
.google-maps__icon--external {
  font-size: 1.6rem;
  float: right;
  margin-right: initial;
}
.google-maps__icon--close {
  vertical-align: initial;
  margin-right: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  transition: all 0.2s;
}
.google-maps__icon-container {
  background-color: transparent;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 10000px;
  position: relative;
  float: right;
  transform: translateX(6%);
  transition: all 0.2s;
  cursor: pointer;
}
.google-maps__icon-container:hover {
  background-color: #e8646e;
}
.google-maps__icon-container:hover > .google-maps__icon {
  color: white;
}
.search {
  display: block;
  border: none;
  padding: 1rem 2rem;
  padding-right: 4.5rem;
  border-radius: 1000px;
  width: 100%;
  box-shadow: 0 0.5rem 1rem #ebebeb;
}
.search:hover + .search__container--copy {
  box-shadow: 0 0.5rem 1.5rem rgba(106, 106, 106, 0.5);
}
.search:focus + .search__container--copy {
  box-shadow: 0 0.5rem 1.5rem rgba(106, 106, 106, 0.5);
}
.search__container {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem;
  position: relative;
  border-radius: 1000px;
}
@media only screen and (min-width: 37.5em) {
  .search__container {
    width: 60%;
  }
}
@media only screen and (min-width: 75em) {
  .search__container {
    width: 50%;
  }
}
.search__container--copy {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  top: 0;
  left: 0;
  z-index: -10000;
  transition: all 0.1s;
}
.search__container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0073b1;
  width: 20%;
  height: 100%;
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
}
.search__icon {
  position: absolute;
  right: 7%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  color: #f6f6f6;
  font-size: 2rem;
}
.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.form__container {
  height: 100vh;
  padding: 4.2rem 0 4.9rem 0;
  position: relative;
}
@media only screen and (min-width: 56.25em) {
  .form {
    width: 60%;
  }
}
.form__input {
  display: block;
  margin: 0 auto;
  width: 100%;
  border: 1px solid rgba(112, 112, 112, 0.07);
  padding: 1.4rem 2.5rem;
  border-radius: 1000px;
  margin-bottom: 2.5rem;
  box-shadow: 0 0.5rem 1rem #ebebeb;
  transition: all 0.2s;
}
.form__input:hover {
  box-shadow: 0 0.5rem 1.5rem rgba(106, 106, 106, 0.5);
}
.form__input:focus {
  outline: none;
  box-shadow: 0 0.5rem 1.5rem rgba(106, 106, 106, 0.5);
}
.form__label {
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: 2rem;
  color: #6a6a6a;
}
.pickup-notifier {
  margin: auto;
  position: relative;
  width: 80%;
  color: #f6f6f6;
  border-radius: 5px;
  padding: 1.2rem 1.5rem;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
.pickup-notifier__extras {
  position: fixed;
  top: 8%;
  right: 2%;
  list-style-type: none;
  color: #f6f6f6;
  font-weight: 600;
  font-size: 1.2rem;
  z-index: 10000;
  overflow-x: auto;
  height: auto;
  max-height: 50%;
  background-color: #f6f6f6;
  padding: 1rem;
  border-radius: 2px;
  border: 1px solid #ebebeb;
  scrollbar-width: thin;
  scrollbar-color: #6a6a6a #ebebeb;
}
.pickup-notifier__extras::-webkit-scrollbar {
  width: 7px;
}
.pickup-notifier__extras::-webkit-scrollbar-track {
  background: #ebebeb;
}
.pickup-notifier__extras::-webkit-scrollbar-thumb {
  background-color: #6a6a6a;
  border-radius: 20px;
  border: 3px solid #6a6a6a;
}
.pickup-notifier__notification {
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.pickup-notifier__notification--extra {
  background-color: #0073b1;
}
.pickup-notifier__notification--removed {
  background-color: #e61f2f;
}
.pickup-notifier__notification-icon {
  margin-right: 0.7rem;
  font-size: 1.3rem;
  float: left;
}
.pickup-notifier__notification-icon--extra {
  color: #283e4a;
}
.pickup-notifier__notification-icon--removed,
.pickup-notifier__notification-icon--message {
  color: #f6f6f6;
}
.pickup-notifier__notification-icon--message {
  float: initial;
}
.pickup-notifier__notification-back-office-msg {
  text-decoration: underline;
  margin-right: 0.5rem;
  cursor: pointer;
}
.pickup-notifier__notification-back-office-msg-text {
  font-weight: 400;
  color: #000000;
}
.pickup-notifier__notification-details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
}
.pickup-notifier__notification-time {
  margin-top: 0.1rem;
  text-align: right;
}
.pickup-notifier__notification-text {
  margin-left: 2.5rem;
}
.pickup-notifier__list {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: 999;
}
.pickup-notifier:not(:last-of-type) {
  margin-bottom: 1rem;
}
.pickup-notifier--expiring {
  background-color: #e61f2f;
  width: calc(80vw);
  left: -10vw;
}
.pickup-notifier--postponed {
  background-color: #0073b1;
  width: calc(80vw);
  left: -10vw;
}
.pickup-notifier--progress {
  background-color: #275167;
  width: calc(80vw);
  left: -10vw;
}
.pickup-notifier--closed {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: initial;
  margin-left: auto;
  margin-right: 3rem;
  left: initial;
}
.pickup-notifier--closed .pickup-notifier__icon {
  margin-right: initial;
}
.pickup-notifier__progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pickup-notifier__progress-dashboard {
  margin-left: 1rem;
  display: flex;
  justify-content: space-around;
  margin: 0.5rem 3rem 0.5rem 1rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup-notifier__progress-dashboard {
    margin: 0.5rem 6rem 0.5rem 1rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup-notifier__progress-dashboard {
    margin: 0.5rem 9rem 0.5rem 1rem;
  }
}
.pickup-notifier__progress-icon-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1e5877;
  font-size: 1.5rem;
}
.pickup-notifier__progress-icon-container--completed {
  color: #24d5d8;
}
.pickup-notifier__progress-icon-container--discarded {
  color: #ff3c4b;
}
.pickup-notifier__progress-icon-container--pending {
  color: #fecd2f;
}
.pickup-notifier__progress-icon-container--expiring {
  color: #f582ff;
}
.pickup-notifier__progress-icon-container--extra {
  color: #52b700;
}
.pickup-notifier__progress-icon:not(:last-of-type) {
  margin-right: 3rem;
}
.pickup-notifier__progress-amounts {
  margin-top: 0.5rem;
}
.pickup-notifier__progress-bar {
  height: 20px;
  background-color: #f6f6f6;
  position: relative;
  margin: 1rem 3rem 0.5rem 1rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup-notifier__progress-bar {
    margin: 1rem 6rem 0.5rem 1rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup-notifier__progress-bar {
    margin: 1rem 9rem 0.5rem 1rem;
  }
}
.pickup-notifier__progress-bar--completed {
  height: 100%;
  background-color: #24d5d8;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
.pickup-notifier__progress-bar--discarded {
  height: 100%;
  background-color: #ff3c4b;
  position: absolute;
  top: 0;
  margin: 0;
}
.pickup-notifier__expectation-line {
  height: 150%;
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  background-color: black;
  transform: translateY(-50%);
}
.pickup-notifier__heading {
  font-size: 1.2rem;
  font-weight: 400;
}
.pickup-notifier__names {
  font-size: 1.3rem;
  padding-left: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 3rem;
}
@media only screen and (min-width: 25.6875em) {
  .pickup-notifier__names {
    margin-right: 6rem;
  }
}
@media only screen and (min-width: 56.25em) {
  .pickup-notifier__names {
    margin-right: 9rem;
  }
}
.pickup-notifier__icon {
  font-size: 2.3rem;
  margin-right: 1rem;
}
.pickup-notifier__icon--exit {
  font-size: 1.7rem;
  margin-right: initial;
  margin-left: 1rem;
  position: absolute;
  right: 5%;
  bottom: 50%;
  transform: translateY(50%);
}
html body.swal2-height-auto {
  height: initial !important;
}
div.swal2-container.swal2-top-end.swal2-backdrop-show {
  top: 8% !important;
  z-index: 100000;
}
.swal2-popup.swal2-toast .swal2-title {
  font-size: 1.1rem !important;
}
.logout-notification--small
  > div.swal2-container.swal2-top-end.swal2-backdrop-show {
  top: 0% !important;
  z-index: 100000;
}
.container-picker {
  margin-bottom: 1.5rem;
}
.container-picker__header {
  margin-bottom: 1.5rem;
}
.container-picker__header:not(:first-of-type) {
  margin-top: 2rem;
}
.container-picker__list {
  list-style-type: none;
}
.container-picker__list-item {
  font-size: 1.3rem;
}
.container-picker__list-item:not(:last-of-type) {
  margin-bottom: 1rem;
}
.container-picker__options-checkbox {
  transform: scale(2);
  padding: 1rem;
}
.container-picker__options-container:not(:last-of-type),
.container-picker__container:not(:last-of-type) {
  margin-bottom: 1.8rem;
}
.container-picker__options-label {
  margin-left: 1.5rem;
}
.distribution-picker__input-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.distribution-picker__input-label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.distribution-picker__input {
  width: 100%;
  height: 30px;
}
.paragraph {
  text-align: center;
  font-size: 1.3rem;
}
.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.8rem 0;
  background-color: white;
  color: #6a6a6a;
  border-top: 1px solid #ebebeb;
  z-index: 1000;
  transition: bottom 0.2s;
}
.navigation--hidden {
  bottom: -4.8rem;
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .navigation--hidden {
    bottom: calc(0px - env(safe-area-inset-bottom) - 4.8rem);
  }
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .navigation {
    padding-bottom: calc(env(safe-area-inset-bottom) + 0.8rem);
  }
}
.navigation__list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navigation__item {
  display: inline;
}
.navigation__link {
  transition: all 0.2s;
}
.navigation__link:link,
.navigation__link:visited {
  text-decoration: none;
  color: #6a6a6a;
  font-weight: 400;
  font-size: 0.9rem;
}
.navigation__link:hover {
  color: #0073b1;
}
.navigation__link--active:link,
.navigation__link--active:visited {
  color: #0073b1;
}
.navigation__icon {
  display: block;
  margin: 0 auto;
  font-size: 1.8rem;
  margin-bottom: 0.1rem;
}
.navigation__dev-logo {
  font-size: 1.5rem;
  color: #38e9e0;
  font-weight: 700;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #283e4a;
  padding: 1.2rem 1.8rem;
  z-index: 1000;
  transition: top 0.2s;
}
.header--hidden {
  top: -4.2rem;
}
.header__logo {
  width: 88px;
}
.section-pending {
  margin-top: 4.2rem;
  margin-bottom: 4.8rem;
  padding-top: 2rem;
  transition: all 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (min-width: 56.25em) {
  .section-pending {
    display: flex;
    padding-top: 0rem;
  }
}
.section-maps {
  padding-top: 4.2rem;
  position: relative;
}
.section-maps--landscape-mode {
  padding-top: initial;
  width: 58%;
  border-left: 1px solid #ebebeb;
}
.section-settings {
  margin-top: 4.2rem;
  padding-top: 2rem;
}
.section-completed {
  margin-top: 4.2rem;
  margin-bottom: 4.8rem;
  padding-top: 2rem;
  transition: all 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (min-width: 56.25em) {
  .section-completed {
    display: flex;
    padding-top: 0rem;
  }
}
.section-route-planner {
  margin-top: 4.2rem;
  margin-bottom: 4.8rem;
}
